/**
 * DevOps
 */
export const ENV_NODE = process.env.REACT_APP_ENV;
export const IS_PROD = ENV_NODE === 'production';

// Azure Application Insights
export const APP_INSIGHTS_CONNECTION_STRING =
  process.env.REACT_APP_INSIGHTS_CONNECTION_STRING;

export const API_PATH = process.env.REACT_APP_API_PATH;
export const OAUTH_API_PATH = process.env.REACT_APP_OAUTH_API_PATH;
export const ONBOARDING_API_PATH = process.env.REACT_APP_ONBOARDING_API_PATH;
export const RBAC_API_PATH = process.env.REACT_APP_RBAC_API_PATH;
export const RBAC_V1_API_PATH = process.env.REACT_APP_RBAC_V1_API_PATH;
export const TOKEN_API_PATH = process.env.REACT_APP_TOKEN_API_PATH;
export const REST_API_PATH = process.env.REACT_APP_REST_API_PATH;
export const OAUTH_LOGIN_URL = `${OAUTH_API_PATH}/login`;
export const OAUTH_LOGOUT_URL = `${OAUTH_API_PATH}/logout`;
export const DOCS_URL = 'https://authz.cloud.zeiss.com/docs';
export const STYRA_URL = 'https://zeiss.svc.styra.com/';
export const basename = '/admin'; // Root path from where the app is served
export const authzTeamsLink =
  'https://teams.microsoft.com/l/channel/19%3aLRtbAa67IDK5X0aeOywAEQX9cZxAz5YE4sStw10CXFk1%40thread.tacv2/General?groupId=553be034-7547-4036-99a3-cf3c41e52b3b&tenantId=28042244-bb51-4cd6-8034-7776fa3703e8';
export const uptimeComLink = 'https://uptime.com/statuspage/zeissidentity';
export const zeissIdUrl = 'https://id.zeiss.com';

// i18n config
export const DEFAULT_LOCALE = 'en';

/**
 * Service disruptions banner
 *
 * Set this to true if we want to show any messages regarding any disruptions
 * to our service and update the `serviceDisruptionTitle` and
 * `serviceDisruptionText` keys in the `common` translations namespace.
 */
export const showServiceDisruptionStatus = false;

// Settings
export const brandingTitle = 'AuthZ';
export const brandingTitleAdminUi = 'Admin UI';
export const copyrightYear = 2025;
export const defaultMutationRetryInterval = 10000;
export const defaultMutationRetryMaxAttempts = 10;
export const defaultPaginationSizes = [10, 25, 50];
export const defaultPollInterval = 5000;
export const enableCustomScrollbar = true;
export const maxBreadcrumbsOnMobile = 3;
export const minCharsForSearch = 3;
export const plainListPaginationSize = 5;
export const DEFAULT_FILTER = 'all';
export const DEFAULT_TABLE_CELL_ITEMS_LIMIT = 3;
export const DEFAULT_TOKEN_EXPIRATION_DAYS = 30;
export const TOKEN_EXPIRY_WARNING_THRESHOLD = 14; // 14 days
export const PERSONAL_TOKEN_TYPES = ['admin'];
export const SESSION_KEEP_ALIVE_INTERVAL_IN_MINUTES = 10;
export const SUPPORTED_TOKEN_MANAGEMENT_SERVICES = [
  'admin',
  'rbac',
  'styra',
] as const;

// Styling
export const appVhWithoutTopBarAndFooter = '100vh - 57px - 56px - 32px';
export const contentMaxWidth = '60rem';
export const defaultBorderRadii = '0.25rem';
export const mainContainerMaxWidth = '81rem';
export const textMaxWidth = '700px';

// Styra
export const styraApiDocsLink = 'https://docs.styra.com/api';
export const styraEntZlink = 'https://zeiss.svc.styra.com';
export const styraDocsLink =
  'https://docs.styra.com/tutorials/entitlements/introduction';

// Docs
export const docsBaseUrl = 'https://authz.cloud.zeiss.com/docs';
export const docsAdminApi = docsBaseUrl + '/api/admin-api';
export const docsGlossary = docsBaseUrl + '/glossary';
export const docsIntegrationIntroduction =
  docsBaseUrl + '/integration/introduction';
export const docsRbac = docsBaseUrl + '/rbac/getting-started/';
export const docsRbacApi = docsBaseUrl + '/api/rbac';
export const docsRbacApiV1 = docsBaseUrl + '/api/rbacv1';
export const docsRbacMigrationGuide = docsBaseUrl + '/rbac/migration-guide';
export const docsRbacApiChangeLog = docsBaseUrl + '/api/changelog';
export const docsRbacDomainModelExample =
  docsBaseUrl + '/rbac/domain-model#example';
export const docsStyraOptions = docsBaseUrl + '/token-management#styra-options';

// Form Validation
export const defaultMaxChars = 60;
export const defaultMinChars = 4;
export const maxCharsOnAllowedPath = 256;
export const maxCharsOnResourcePattern = 256;
export const maxCharsOnStackAction = 60;
export const maxCharsOnStackName = 60;
export const maxCharsOnStackScope = 60;
export const maxCharsOnSubjectId = 60;
export const minCharsOnAllowedPath = 1;
export const minCharsOnResourcePattern = 1;
export const minCharsOnStackAction = 3;
export const minCharsOnStackName = 4;
export const minCharsOnStackScope = 3;
export const minCharsOnSubjectId = 1;

/**
 * Fallback value for 'not available' values, e.g an empty table cell
 */
export const notAvailableValue = '—';

/**
 * Feature Flags
 * The feature flags are defined as roles and role-bindings in the `authz-feature-flags` stack
 * See https://dev.azure.com/ZEISSgroup/ZEISS%20AUTHZ/_wiki/wikis/Wiki/54965/Feature-Flags-Management-Process
 *
 * Below we centrally specify currently active features as constants e.g:
 * export const GETTING_STARTED_GUIDE = 'GETTING_STARTED_GUIDE'
 */
export const GETTING_STARTED_GUIDE = 'GETTING_STARTED_GUIDE';
